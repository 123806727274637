import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../containers/layout';
import BasePortableText from '@sanity/block-content-to-react'
import clientConfig from '../../client-config';
import CheckoutModal from '../components/checkout/CheckoutModal/CheckoutModal';
import ContentCard from '../components/ContentCardTest/ContentCard';
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String) {
    sanityZwangerConnect(slug: { current: { eq: $slug } }) {
      id
      title
      priceId
      description
      slug {
        current
      }
      disableLesson,
      thumbnail {
        asset {
          url
        }
      }
      _rawBody(resolveReferences: {maxDepth: 5})
    }
  }
`;

export default ({ data }) => {
  const { title, thumbnail, description, disableLesson } = data.sanityZwangerConnect;
  const [showContact, setShowContact] = React.useState(false);

  const handleContactClose = React.useCallback(() => {
    setShowContact(false);
  }, []);

  return (
    <Layout>
      <SEO title={title} />
      <section className='about-us'>
        <div className="wrap">
          <div className="row">
            <ContentCard size="medium" horizontal image={thumbnail.asset.url}>
              <div>
                <h2>{title}</h2>
                <p>{description}</p>
                {!disableLesson && (
                  <button className='button' onClick={() => setShowContact(!showContact)}>Deelnemen</button>
                )}
              </div>
            </ContentCard>
          </div>
        </div>
      </section>
      <div className="content-block">
        <div className="wrap">
          <div className="row center-lg">
            <div className="col-sm-12 col-lg-10">
              <BasePortableText
                blocks={data.sanityZwangerConnect._rawBody} {...clientConfig.sanity} />
            </div>
          </div>
        </div>
      </div>
      <CheckoutModal
        open={showContact}
        lesson={data.sanityZwangerConnect}
        onClose={handleContactClose}
      />
    </Layout>
  )
}
